import {
  FileWordIcon, FileCopyIcon, TranslateIcon
} from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/content",
    name: "content",
    component: Layout,
    meta: { title: "内容库", icon: FileCopyIcon },
    children: [
      {
        path: "/content/product",
        name: "contentProduct",
        meta: {
          auth: [],
          title: "商品内容",
          keepAlive: true,
          isBack: false
        },
        component: () => import("@/pages/content/product/index.vue")
      },
      // {
      //   path: "/content/push",
      //   name: "contentPush",
      //   meta: {
      //     auth: [],
      //     title: "推品管理"
      //   },
      //   component: () => import("@/pages/content/push/index.vue")
      // },
      {
        path: "/content/article",
        name: "contentArticle",
        meta: {
          auth: [],
          title: "文章活动",
          keepAlive: true,
        },
        component: () => import("@/pages/content/article/index.vue")
      },
      {
        path: "/content/live",
        name: "contentLive",
        meta: {
          auth: [],
          title: "直播洞察",
          keepAlive: true,
        },
        component: () => import("@/pages/content/live/index.vue")
      },
      {
        path: "/content/netdisk",
        name: "contentNetdisk",
        meta: {
          auth: [],
          title: "企业微盘"
        },
        component: () => import("@/pages/content/netdisk/index.vue")
      },
      {
        path: "/content/source",
        name: "contentSource",
        meta: {
          auth: [],
          title: "资源管理"
        },
        component: () => import("@/pages/content/source/index.vue")
      },

    ]
  }
];
