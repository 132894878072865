
<div>
  <template v-if="setting.layout === 'side'">
    <t-layout key="side">
      <t-aside><layout-sidebar /></t-aside>
      <t-layout>
        <t-header><layout-header /></t-header>
        <t-content><layout-content /></t-content>
      </t-layout>
    </t-layout>
  </template>
  <template v-else-if="setting.layout === 'top'">
    <t-layout key="top">
      <t-header> <layout-header /></t-header>
      <t-content><layout-content /></t-content>
    </t-layout>
  </template>
  <template v-else>
    <t-layout key="mix">
      <t-header><layout-header /></t-header>
      <t-layout>
        <t-aside><layout-sidebar /></t-aside>
        <t-content><layout-content /></t-content>
      </t-layout>
    </t-layout>
  </template>
  <setting />
</div>
