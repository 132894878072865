<template>
  <div :class="layoutCls">
    <t-head-menu :class="menuCls" :theme="theme" expandType="popup" :value="active">
      <template #logo>
        <span v-if="showLogo" class="header-logo-container" @click="handleNav('/dashboard/base')">
          <logo-full class="t-logo" />
        </span>
        <div v-else class="header-operate-left">
          <t-button theme="default" aria-label="search" shape="square" variant="text" @click="changeCollapsed">
            <view-list-icon  class="collapsed-icon" />
          </t-button>
        </div>
      </template>
      <menu-content v-show="layout !== 'side'" style="color: #FFFFFF" class="header-menu" :navData="menu" />
      <template #operations>
        <div class="operations-container">
          <t-dropdown :min-column-width="125" trigger="click">
            <template #dropdown>
              <t-dropdown-menu>
                <t-dropdown-item class="operations-dropdown-container-item" @click="bingFs">
                  <App-icon />
                  绑定飞书
                </t-dropdown-item>
                <t-dropdown-item class="operations-dropdown-container-item" @click="handleLogout">
                  <poweroff-icon />
                  退出登录
                </t-dropdown-item>
              </t-dropdown-menu>
            </template>
            <t-button aria-label="avatar" class="header-user-btn" theme="default" variant="text">
              <template #icon>
                <user-circle-icon class="header-user-avatar" />
              </template>
              <div style="display: flex;align-items: center">
                <div class="header-user-account">{{ nickname }}</div>
                <t-tag
                  style="margin-left: 10px" v-if="roles.length>0" v-for="item in roles" :key="item" theme="success"
                  size="small" variant="light"
                >{{ item }}
                </t-tag>
              </div>
              <template #suffix>
                <chevron-down-icon />
              </template>
            </t-button>
          </t-dropdown>
        </div>
      </template>
    </t-head-menu>
  </div>
</template>
<script>
import Vue from "vue";
import {
  ViewListIcon,
  LogoGithubIcon,
  HelpCircleIcon,
  UserCircleIcon,
  PoweroffIcon,
  AppIcon,
  SettingIcon,
  ChevronDownIcon
} from "tdesign-icons-vue";
import { prefix } from "@/config/global";
import LogoFull from "@/assets/assets-logo-full.svg";
import Notice from "./Notice.vue";
import MenuContent from "./MenuContent.vue";
import { userInfoApi } from "@/api/account";

export default Vue.extend({
  components: {
    MenuContent,
    LogoFull,
    Notice,
    AppIcon,
    ViewListIcon,
    LogoGithubIcon,
    HelpCircleIcon,
    UserCircleIcon,
    PoweroffIcon,
    SettingIcon,
    ChevronDownIcon
  },
  props: {
    theme: String,
    layout: {
      type: String,
      default: "top"
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    menu: {
      type: Array
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    maxLevel: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      prefix,
      roles: [],
      visibleNotice: false,
      isSearchFocus: false,
      nickname: "个人"
    };
  },
  mounted() {
    this.roles = JSON.parse(localStorage.getItem("roles"));
    this.nickname = localStorage.getItem("name");
  },
  computed: {
    active() {
      if (!this.$route.path) {
        return "";
      }
      return this.$route.path
        .split("/")
        .filter((item, index) => index <= this.maxLevel && index > 0)
        .map((item) => `/${item}`)
        .join("");
    },
    showMenu() {
      return !(this.layout === "mix" && this.showLogo === "side");
    },
    layoutCls() {
      return [`${this.prefix}-header-layout`];
    },
    menuCls() {
      return [
        {
          [`${this.prefix}-header-menu`]: !this.isFixed,
          [`${this.prefix}-header-menu-fixed`]: this.isFixed,
          [`${this.prefix}-header-menu-fixed-side`]: this.layout === "side" && this.isFixed,
          [`${this.prefix}-header-menu-fixed-side-compact`]: this.layout === "side" && this.isFixed && this.isCompact
        }
      ];
    }
  },
  methods: {
    toggleSettingPanel() {
      this.$store.commit("setting/toggleSettingPanel", true);
    },
    bingFs() {
      userInfoApi().then((res) => {
        if (res.data.bind_fs_url) {
          window.open(res.data.bind_fs_url);
        } else {
          this.$message.warning("已绑定飞书账号,请勿重复绑定");
        }
      });
    },
    handleLogout() {
      this.$router.push(`/login`);
      localStorage.removeItem("token");
    },
    changeCollapsed() {
      this.$store.commit("setting/toggleSidebarCompact");
    },
    handleNav(url) {
      this.$router.push(url);
    },
    navToGitHub() {
      window.open("https://github.com/Tencent/tdesign-vue-starter");
    },
    navToHelper() {
      window.open("http://tdesign.tencent.com/starter/docs/get-started");
    }
  }
});
</script>
<style lang="less">
@import '@/style/variables.less';

.header-menu {
  flex: 1 1;
  display: inline-flex;
}

.operations-container {
  display: flex;
  align-items: center;
  margin-right: 12px;

  .t-popup__reference {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .t-button {
    margin: 0 8px;

    &.header-user-btn {
      margin: 0;
    }
  }

  .t-icon {
    font-size: 20px;

    &.general {
      margin-right: 16px;
    }
  }
}

.header-operate-left {
  display: flex;
  margin-left: 20px;
  align-items: normal;
  line-height: 0;

  .collapsed-icon {
    font-size: 20px;
  }
}

.header-logo-container {
  width: 184px;
  height: 26px;
  display: flex;
  margin-left: 24px;
  color: var(--td-text-color-primary);

  .t-logo {
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.header-user-account {
  display: inline-flex;
  align-items: center;
  color: var(--td-text-color-primary);

  .t-icon {
    margin-left: 4px;
    font-size: 16px;
  }
}

.t-head-menu__inner {
  border-bottom: 1px solid var(--td-border-level-1-color);
}

.t-menu--light {
  .header-user-account {
    color: var(--td-text-color-primary);
  }
}

.t-menu--dark {
  .t-head-menu__inner {
    border-bottom: 1px solid var(--td-gray-color-10);
  }

  .header-user-account {
    color: rgba(255, 255, 255, 0.55);
  }

  .t-button {
    --ripple-color: var(--td-gray-color-10) !important;

    &:hover {
      background: var(--td-gray-color-12) !important;
    }
  }
}

.operations-dropdown-container-item {
  width: 100%;
  display: flex;
  align-items: center;

  .t-icon {
    margin-right: 8px;
  }

  .t-dropdown__item {
    .t-dropdown__item__content {
      display: flex;
      justify-content: center;
    }

    .t-dropdown__item__content__text {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  .t-dropdown__item {
    width: 100%;
    margin-bottom: 0px;
  }

  &:last-child {
    .t-dropdown__item {
      margin-bottom: 8px;
    }
  }
}
</style>
