
<div class="page">
  <t-card :bordered="false" hover-shadow size="small">
    <t-pagination
      :total="total"
      :page-size.sync="limitSize"
      v-model="page"
      show-sizer
      @current-change="pageChange"
      @page-size-change="pageSizeChange"
      :page-size-options="pageSizeOptions"
    />
  </t-card>
</div>
