<template>
  <keep-alive>
    <router-view :class="[mode]" />
  </keep-alive>
</template>
<style lang="css">
@import "../src/assets/theme.css";
</style>
<style>
.t-table .custom-cell-mini > td {
  padding: 2px;
  margin: 0;
}

.t-table .custom-cell-small > td {
  padding: 5px;
  margin: 0;
}

.t-form__item {
  margin-bottom: 0;
}

.t-notification__content {
  color: #000000;
  margin-bottom: 5px;
}

.t-notification .t-icon-close {
  color: #000000;
}

.notify-div {
  div:hover {
    cursor: pointer;
  }
}
</style>
<script lang="jsx">
import Vue from "vue";
import config from "@/config/style";
import { NotifyPlugin } from "tdesign-vue";
import host from "@/config/host";

export default Vue.extend ( {
  data () {
    return {
      arr: []
    };
  } ,
  computed: {
    mode () {
      return this.$store.getters["setting/mode"];
    }
  } ,
  created () {
    this.evtSource.addEventListener ( "message.order.new" , ( e ) => {
      if ( this.$route.name && this.$route.name !== "login" ) {
        this.arr.push ( e.data );
        if ( this.arr.length === 4 ) {
          this.arr = [];
          this.$notification.closeAll ();
          this.arr.push ( e.data );
        }
        if ( this.arr.length <= 3 ) {
          this.$notify.info ( {
            title: () => (
              <div
                style="cursor:pointer;"
                onclick={ () => window.open ( "/order/detail?order_id=" + e.data ) }
              >
                收到新的订单
              </div>
            ) ,
            content: () => (
              <div
                style="cursor:pointer;"
                onclick={ () => window.open ( "/order/detail?order_id=" + e.data ) }
              >
                订单编号:{ e.data }
              </div>
            ) ,
            onDurationEnd: () => {
              this.arr = [];
            } ,
            onCloseBtnClick: () => {
              this.arr = [];
            } ,
            duration: 60000 ,
            closeBtn: true
          } );
        }
      }
    } );
    this.evtSource.addEventListener ( "message.system.notify" , ( e ) => {
      if ( this.$route.name && this.$route.name !== "login" ) {
        this.$notify.info ( {
          title: "系统通知" ,
          content: e.data ,
          duration: 5000 ,
          closeBtn: true
        } );
      }
    } );
  } ,
  mounted () {
    this.$store.dispatch ( "setting/changeTheme" , { ...config } );
  }
} );
</script>
