export default {
    "showFooter": true,
    "isSidebarCompact": false,
    "showBreadcrumb": false,
    "mode": "light",
    "layout": "side",
    "splitMenu": false,
    "isFooterAside": false,
    "isSidebarFixed": true,
    "isHeaderFixed": true,
    "isUseTabsRouter": false,
    "showHeader": true,
    "brandTheme": "#07C160"
}
