import axios from "axios";
import { MessagePlugin } from "tdesign-vue";
import host from "@/config/host";
import tbcHost from "@/config/tbcHost";
const env = import.meta.env.MODE || "development";
let  API_HOST = host[env].API
// 记录和显示错误
function errorLog(err) {
  // 添加到日志
  MessagePlugin.error(err.message);
}
const service = axios.create({
  baseURL: API_HOST,
  timeout: 600000,
  withCredentials: true
});
service.defaults.withCredentials = false;// 携带cookie
// 网络加载
// eslint-disable-next-line
// @ts-ignore
// axios的retry ts类型有问题
service.interceptors.retry = 3;
let needLoadingRequestCount = 0;

export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    // startLoading()
  }
  needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    // endLoading()
  }
}

service.interceptors.request.use(
  async config => {
    config.baseURL =API_HOST
    // console.log(config.baseURL, 'config.baseURL----')
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // 开启loading
    showFullScreenLoading();
    return config;
  },
  error => {
    // 发送失败
    Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
    // 获取服务器类型
    if (!sessionStorage.getItem("SERVER_TYPE")) {
      sessionStorage.setItem("SERVER_TYPE", response.headers["server"]);
    }
    // 关闭loading
    tryHideFullScreenLoading();
    const code = response.data.code;
    if (code === 200) {
      return response.data;
    }
    if (code !== 200 && code !== 401) {
      return MessagePlugin.error(response.data.message);
    }
    if (code === 401) {
      MessagePlugin.error(response.data.message);
      return window.location.href = "/login";
    }
  },
  error => {
    errorLog(error);
    // return Promise.reject(error);
  }
);
export default service;
