
<div :class="sideNavCls">
  <t-menu
    width="180px"
    style="background: linear-gradient(rgb(6, 197, 143), rgb(7, 193, 96));"
    :class="menuCls"
    :theme="theme"
    :value="active"
    :collapsed="collapsed"
    :defaultExpanded="defaultExpanded"
  >
    <template #logo>
      <span v-if="showLogo" :class="`${prefix}-side-nav-logo-wrapper`" @click="() => handleNav('/dashboard/index')">
        <component :is="getLogo" :class="`${prefix}-side-nav-logo-${collapsed ? 't' : 'tdesign'}-logo`" />
      </span>
    </template>
    <menu-content :navData="menu"/>
  </t-menu>
  <div :class="`${prefix}-side-nav-placeholder${collapsed ? '-hidden' : ''}`"></div>
</div>
