import Vue from "vue";
import VueRouter from "vue-router";
import { sync } from "vuex-router-sync";
import TDesign from "tdesign-vue";
import VueClipboard from "vue-clipboard2";
import axiosInstance from "@/utils/request";
import formatDate from "./components/format/formatDate";
import App from "./App.vue";
import router from "./router";
import zhConfig from "tdesign-vue/es/locale/zh_CN";
import timeOptions from "@/utils/timeOptions";
import { mapGetters } from "vuex";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import "tdesign-vue/es/style/index.css";
import "@/style/index.less";
import Pagination from "@/components/pagination/index.vue";
import "./permission";
import store from "./store";
import * as echarts from "echarts";
import host from "@/config/host";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

Vue.use ( Viewer , {
  defaultOptions: {
    zIndex: 9999
  }
} );
Vue.use ( ViewUI );
Vue.use ( VueRouter );
Vue.use ( TDesign );
Vue.use ( VueClipboard );
Vue.use ( formatDate );
// Vue.use(Pagination);
Vue.component ( "Pagination" , Pagination );
Vue.component ( "t-page-header" );
Vue.prototype.$echarts = echarts;
//    "postbuild": "node ./build/build.js",
Vue.prototype.$request = axiosInstance;
const env = import.meta.env.MODE || "development";
const evtSource = new EventSource ( host[env].API + "/evt/source" );
Vue.prototype.evtSource = evtSource;
let API_HOST = host[env].API;
Vue.prototype.$presets = timeOptions;
Vue.prototype.$uploadImg = API_HOST + "/pro/tool/mp/file/upload";
Vue.prototype.$uploadVideo = API_HOST + "/pro/tool/mp/video/upload";
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push ( location ) {
  return originPush.call ( this , location ).catch ( ( err ) => err );
};
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace ( location ) {
  return originReplace.call ( this , location ).catch ( ( err ) => err );
};
// 表格高度自适应 内容最大区域 main-area 差值 top-diff
Vue.prototype.$autoTableHeight = ( context , is_tab ) => {
  document.getElementsByClassName ( "main-area" )[0].style.height =
    document.getElementsByClassName ( "tdesign-starter-content-layout" )[0].clientHeigh ? document.getElementsByClassName ( "tdesign-starter-content-layout" )[0].clientHeigh + "px" :
    "100%";
  setTimeout ( () => {
    let ele_arr = document.getElementsByClassName ( "top-diff" );
    if ( is_tab ) {
      let t_tabs = document.getElementsByClassName ( "t-tabs__nav-wrap" );
      ele_arr = [ ...ele_arr , ...t_tabs ];
    }
    let diff = 80;
    for ( let i = 0 ; i < ele_arr.length ; i ++ ) {
      diff += ele_arr[i].clientHeight;
    }
    context.table_max_height = document.getElementsByClassName ( "tdesign-starter-content-layout" )[0].clientHeight - diff;
  } , 400 );
};
Vue.config.productionTip = false;
sync ( store , router );
new Vue ( {
  router ,
  store ,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render: ( h ) => (
    <div>
      {/* 可以通过config-provider提供全局（多语言、全局属性）配置，如
      <t-config-provider globalConfig={enConfig}> */ }
      <t-config-provider globalConfig={ zhConfig }>
        <App />
      </t-config-provider>
    </div>
  )
} ).$mount ( "#app" );
