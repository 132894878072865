import Layout from "@/layouts/index.vue";
import {
  CreditcardIcon,
  DiscountIcon,
  GiftIcon,
  RelativityIcon,
  StarIcon,
  SwapIcon,
  TranslateIcon
} from "tdesign-icons-vue";

export default [
  {
    path: "/marketing",
    name: "marketing",
    component: Layout,
    redirect: "/marketing/coupon",
    meta: { title: "营销工具", icon: GiftIcon },
    children: [
      {
        path: "/marketing/coupon",
        name: "marketingCoupon",
        meta: {
          keepAlive: true,
          title: "优惠券"
        },
        component: () => import("@/pages/marketing/coupon/index.vue")
      },
      {
        path: "/marketing/task",
        name: "marketingTask",
        meta: {
          keepAlive: true,
          title: "积分活动"
        },
        component: () => import("@/pages/marketing/task/index.vue")
      },
      {
        path: "/marketing/homeManage",
        name: "marketinghomeManage",
        meta: {
          keepAlive: true,
          title: "首页运营"
        },
        component: () => import("@/pages/marketing/homePageManage/index.vue")
      },
      {
        path: "/marketing/sales",
        name: "marketingSales",
        meta: {
          keepAlive: true,
          title: "活动运营"
        },
        component: () => import("@/pages/marketing/marketingSetting/index.vue")
      },
      {
        path: "/marketing/joinFans",
        name: "marketingJoinFans",
        meta: {
          keepAlive: true,
          title: "加粉引导"
        },
        component: () => import("@/pages/marketing/joinFansGuidance/index.vue")
      },
      {
        path: "/marketing/SpecialManage",
        name: "marketingSpecialManage",
        meta: {
          keepAlive: true,
          title: "专题管理"
        },
        component: () => import("@/pages/marketing/specialManage/index.vue")
      },
      {
        path: "/marketing/vip",
        name: "marketingVip",
        meta: {
          keepAlive: true,
          title: "会员管理"
        },
        component: () => import("@/pages/marketing/vip/index.vue")
      },
      {
        path: "/marketing/notify",
        name: "marketingNotify",
        meta: {
          keepAlive: true,
          title: "消息触点"
        },
        component: () => import("@/pages/marketing/notify/index.vue")
      },
      {
        path: "/marketing/buy",
        name: "marketingBuy",
        meta: {
          keepAlive: true,
          title: "买了又买"
        },
        component: () => import("@/pages/marketing/buy/index.vue")
      }
    ]
  }
];
