import Layout from "@/layouts/index.vue";
import { DashboardIcon, DesktopIcon } from "tdesign-icons-vue";


export default [
  {
    path: "/advertisement",
    name: "advertisement",
    component: Layout,
    meta: { title: "广告营销", icon: DesktopIcon },
    children: [
      {
        path: "/advertisement/manage",
        name: "advertisementManage",
        component: () => import("@/pages/advertisement/advertisementManage/index.vue"),
        meta: {
          title: "广告管理",
          keepAlive: false,   //此组件需要被缓存
        }
      },
      {
        path: "/advertisement/plan-report",
        name: "advertisementPlanReport",
        component: () => import("@/pages/advertisement/planReport/index.vue"),
        meta: {
          title: "计划报表",
          keepAlive: false,   //此组件需要被缓存
        }
      },
      {
        path: "/advertisement/creative-report",
        name: "advertisementCreativeReport",
        component: () => import("@/pages/advertisement/creativeReport/index.vue"),
        meta: {
          title: "创意报表",
          keepAlive: false,   //此组件需要被缓存
        }
      },

      {
        path: "/advertisement/comprehensive",
        name: "advertisementComprehensive",
        component: () => import("@/pages/advertisement/comprehensive/index.vue"),
        meta: {
          title: "综合报表",
          keepAlive: true,   //此组件需要被缓存
        }
      },
    ]
  }
];
