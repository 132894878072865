import {
  ChartBubbleIcon, FileWordIcon, VideoIcon, LaptopIcon
} from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/radar",
    name: "radar",
    component: Layout,
    meta: { title: "选品库", icon: ChartBubbleIcon },
    children: [
      {
        path: "/radar/product",
        name: "radarProduct",
        meta: {
          auth: [],
          keepAlive: true,
          title: "商品挖掘"
        },
        component: () => import("@/pages/radar/radarProduct/index.vue")
      },
      {
        path: "/radar/article",
        name: "radarArticle",
        meta: {
          auth: [],
          keepAlive: true,
          title: "文章选品"
        },
        component: () => import("@/components/result/developing.vue")
      },
      {
        path: "/radar/video",
        name: "radarVideo",
        meta: {
          auth: [],
          keepAlive: true,
          title: "视频选品"
        },
        component: () => import("@/components/result/developing.vue")
      }
    ]
  }
];
