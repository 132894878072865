import Layout from "@/layouts/index.vue";
import { FormatVerticalAlignLeftIcon, LogoWecomIcon, UsergroupAddIcon, UserIcon } from "tdesign-icons-vue";

export default [
  {
    path: "/privateDomain",
    name: "privateDomain",
    component: Layout,
    meta: { title: "私域管理", icon: LogoWecomIcon },
    redirect: "/privateDomain/userList",
    children: [
      {
        path: "/privateDomain/staffDashboard",
        name: "staffDashboard",
        meta: {
          title: "私域数据",
          keepAlive: true,
          isRemovePadding: true
        },
        component: () => import("@/pages/privateDomain/wwStaffDashboard/index.vue")
      },
      {
        path: "/privateDomain/teamManage",
        name: "teamManage",
        meta: {
          keepAlive: true,
          title: "团队管理"
        },
        component: () => import("@/pages/privateDomain/teamManage/index.vue")
      },
      {
        path: "/privateDomain/userList",
        name: "userList",
        meta: {
          keepAlive: true,
          title: "私域用户"
        },
        component: () => import("@/pages/privateDomain/userManage/index.vue")
      },
      {
        path: "/privateDomain/teamDashboard",
        name: "teamDashboard",
        meta: {
          title: "私域日龄",
          keepAlive: true,
          isRemovePadding: true
        },
        component: () => import("@/pages/privateDomain/WkjDashboard/index.vue")
      },
      {
        path: "/privateDomain/drainage",
        name: "drainage",
        meta: {
          title: "引流配置",
          keepAlive: true,
          isRemovePadding: true
        },
        component: () => import("@/pages/privateDomain/drainage/index.vue")
      }
    ]
  }
];
