/*
 * @Author: 向佐贵 xiangzg@weixinkd.com
 * @Date: 2024-03-13 17:01:16
 * @LastEditors: 向佐贵 xiangzg@weixinkd.com
 * @LastEditTime: 2024-06-18 15:00:07
 * @FilePath: /youth-mall-admin-new/src/router/modules/xbProduct.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layouts/index.vue";
// @ts-ignore
import { UserAddIcon, ViewListIcon, RootListIcon } from "tdesign-icons-vue";

export default [
  {
    path: "/xb",
    name: "xb",
    component: Layout,
    redirect: "/xb/product/report",
    meta: { title: "小白专区", icon:RootListIcon},
    children: [
      {
        path: "product/report",
        name: "productReport",
        meta: {
          keepAlive: true,
          title: "商品列表"
        },
        component: () => import("@/pages/xbReport/index.vue")
      },
      {
        path: "order/list",
        name: "orderList",
        meta: {
          keepAlive: true,
          title: "订单列表"
        },
        component: () => import("@/pages/xbReport/order.vue")
      },
      {
        path: "data/report",
        name: "dataReport",
        meta: {
          keepAlive: true,
          title: "数据报表"
        },
        component: () => import("@/pages/xbReport/dataRepot.vue")
      },
      {
        path: "data/award",
        name: "dataAward",
        meta: {
          keepAlive: true,
          title: "中奖配置"
        },
        component: () => import("@/pages/xbReport/awardConfig.vue")
      }
    ]
  }
];
