
<div>
  <t-drawer
    size="408px"
    :footer="false"
    :visible.sync="showSettingPanel"
    header="页面配置"
    :closeBtn="true"
    :onCloseBtnClick="handleCloseDrawer"
    class="setting-drawer-container"
  >
    <div class="setting-container">
      <t-form :data="formData" size="large" ref="form" labelAlign="left" @reset="onReset" @submit="onSubmit">
        <div class="setting-group-title">主题模式</div>
        <t-radio-group v-model="formData.mode">
          <div v-for="(item, index) in MODE_OPTIONS" :key="index" class="setting-layout-drawer">
            <div>
              <t-radio-button :key="index" :value="item.type"
                ><component :is="getModeIcon(item.type)"
              /></t-radio-button>
              <p :style="{ textAlign: 'center', marginTop: '8px' }">{{ item.text }}</p>
            </div>
          </div>
        </t-radio-group>
      </t-form>
    </div>
  </t-drawer>
</div>
