import Layout from "@/layouts/index.vue";
import { DashboardIcon, DesktopIcon } from "tdesign-icons-vue";

const role = localStorage.getItem("token");
export default [
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: "/dashboard/index",
    component: Layout,
    meta: {
      title: "仪表板",
      icon: DashboardIcon,
      keepAlive: true,
      single: true
    },
    children: [
      {
        path: "/dashboard/index",
        name: "dashboardIndex",
        component: () => import("@/pages/dashboard/index.vue"),
        meta: {
          title: "综合报表", icon: DesktopIcon,
          keepAlive: true,
        }
      }
    ]
  }
];
