import Layout from "@/layouts/index.vue";
import { DashboardIcon, DesktopIcon } from "tdesign-icons-vue";


export default [
  {
    path: "/ai",
    name: "ai",
    component: Layout,
    meta: { title: "AI大模型", icon: DashboardIcon },
    children: [
      {
        path: "/ai/qsgpt",
        name: "qsgpt",
        component: () => import("@/pages/ai/qs/index.vue"),
        meta: {
          title: "私域机器人",
          keepAlive: true,   //此组件需要被缓存
        }
      },
      {
        path: "/ai/aac",
        name: "aac",
        component: () => import("@/pages/ai/aac/index.vue"),
        meta: {
          title: "AudioAi",
          keepAlive: true,   //此组件需要被缓存
        }
      },
      {
        path: "/ai/chat",
        name: "aiChat",
        component: () => import("@/pages/ai/chat/index.vue"),
        meta: {
          title: "Chat",
          keepAlive: true,   //此组件需要被缓存
        }
      },
      {
        path: "/ai/ocr",
        name: "aiOcr",
        component: () => import("@/pages/ai/ocr/index.vue"),
        meta: {
          title: "拍照搜题",
          keepAlive: true,
        }
      },
      {
        path: "/ai/img",
        name: "aiImg",
        component: () => import("@/pages/ai/removeBg/index.vue"),
        meta: {
          title: "背景移除",
          keepAlive: true,
        }
      },
      {
        path: "/ai/image",
        name: "aiImage",
        meta: {
          auth: [],
          title: "AI制图"
        },
        component: () => import("@/pages/content/ai/index.vue")
      },

    ]
  }
];
