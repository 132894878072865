import {
  BooksIcon, UnfoldMoreIcon, ViewModuleIcon, ServiceIcon, FlagIcon
} from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/order",
    name: "order",
    component: Layout,
    meta: { title: "订单管理", icon: BooksIcon },
    children: [
      {
        path: "/order/store",
        name: "storeOrder",
        meta: {
          auth: [],
          keepAlive: true,
          title: "商城订单"
        },
        component: () => import("@/pages/order/storeOrder/index.vue")
      },
      {
        path: "/order/refund",
        name: "refundOrder",
        meta: {
          auth: [],
          keepAlive: true,
          title: "售后订单"
        },
        component: () => import("@/pages/order/refundOrder/index.vue")
      },
      {
        path: "/order/supplier",
        name: "supplierOrder",
        meta: {
          auth: [],
          keepAlive: true,
          title: "导入导出"
        },
        component: () => import("@/pages/order/supplierOrder/index.vue")
      }
    ]
  }
];
