import request from "@/utils/request.ts";

/*
 * 用户详情
 * */
export function userInfoApi(data) {
  return request({
    url: "/pro/system/user/info",
    method: "post",
    data
  });
}

/*
 * 用户详情
 * */
export function systemUserBindFs(data) {
  return request({
    url: "/pro/system/user/bind/fs",
    method: "post",
    data
  });
}
