export default {
  install(Vue) {
    Vue.prototype.getTime = function(time) {
      if (time === 0) {
        return "-";
      }
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10 ?
          "0" + (date.getMonth() + 1) :
          date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    };
    Vue.prototype.getActiveTime = function(time) {
      if (time === 0) {
        return "-";
      }
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10 ?
          "0" + (date.getMonth() + 1) :
          date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return  M + D + h + m + s;
    };
    Vue.prototype.getDate2 = function(time) {
      if (time === 0) {
        return "-";
      }
      let date = new Date(time);
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return h + m + s;
    };
    Vue.prototype.getDate = function(time) {
      if (time === 0) {
        return "-";
      }
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10 ?
          "0" + (date.getMonth() + 1) :
          date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      return Y + M + D;
    };
    Vue.prototype.getDetailsDate = function(ts, is_diff = false){
       let ts_text = '';
       let now_ts = new Date().getTime()/1000 - ts
       if( is_diff ){
        now_ts = ts
       }

      if(now_ts<60){
        ts_text = Math.floor(now_ts) + '秒'
      }else if(now_ts>=60 && now_ts<3600){
        ts_text = Math.floor(now_ts/60) + '分钟'
      }else if(now_ts>=3600 && now_ts<86400){
        ts_text = Math.floor(now_ts/3600 )+ '小时'
      }else if(now_ts>=86400 && now_ts<(86400*30)){
        ts_text = Math.floor(now_ts/86400) + '天'
      }else if(now_ts>=(86400*30) && now_ts<(86400*30*12)){
        ts_text = Math.floor(now_ts/(86400*30)) + '月'
      }else if(now_ts>=(86400*30*12)){
        ts_text = Math.floor(now_ts/(86400*30*12)) + '年'
      }
      return ts_text
    }
    Vue.prototype.getSimpleDate = function(time) {
      if (time === 0) {
        return "-";
      }
      let date = new Date(time);
      let M =
        (date.getMonth() + 1 < 10 ?
          "0" + (date.getMonth() + 1) :
          date.getMonth() + 1) + "/";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      return M + D;
    };
  }
};
