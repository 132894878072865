import Layout from "@/layouts/index.vue";
import { UserAddIcon, ViewListIcon, ViewModuleIcon } from "tdesign-icons-vue";

export default [
  {
    path: "/user",
    name: "user",
    component: Layout,
    redirect: "/user/list",
    meta: { title: "用户管理", icon: UserAddIcon },
    children: [
      {
        path: "quality",
        name: "quality",
        meta: {
          keepAlive: true,
          title: "价值用户"
        },
        component: () => import("@/pages/user/quality/index.vue")
      },
      {
        path: "employees",
        name: "employees",
        meta: {
          keepAlive: true,
          title: "企业员工"
        },
        component: () => import("@/pages/user/enterpriseEmployees/index.vue")
      },
      {
        path: "list",
        name: "list",
        meta: {
          keepAlive: true,
          title: "用户列表"
        },
        component: () => import("@/pages/user/list/index.vue")
      }
    ]
  }
];
