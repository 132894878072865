import {
  SettingIcon
} from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/setting",
    name: "setting",
    component: Layout,
    meta: { title: "系统设置", icon: SettingIcon },
    children: [
      {
        path: "/setting/staff",
        name: "settingStaff",
        meta: {
          auth: ["setting-system-role"],
          keepAlive: true,
          title: "员工权限"
        },
        component: () => import("@/pages/setting/systemStaff/index.vue")
      },
      {
        path: "/setting/opt-log",
        name: "settingOptLog",
        meta: {
          keepAlive: true,
          title: "操作日志"
        },
        component: () => import("@/pages/setting/systemLog/index.vue")
      },
      {
        path: "/setting/internal-entry",
        name: "internalEntry",
        meta: {
          keepAlive: true,
          title: "系统参数"
        },
        component: () => import("@/pages/setting/internalEntry/index.vue")
      },
      {
        path: "/setting/monitor",
        name: "settingMonitor",
        meta: {
          keepAlive: true,
          title: "小程序埋点"
        },
        component: () => import("@/pages/setting/clearUser/index.vue")
      }
    ]
  }
];
