/*
 * @Author: 向佐贵 xiangzg@weixinkd.com
 * @Date: 2023-12-12 09:46:35
 * @LastEditors: 向佐贵 xiangzg@weixinkd.com
 * @LastEditTime: 2024-06-24 17:46:13
 * @FilePath: /youth-mall-admin-new/src/router/modules/supplier.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { UsergroupIcon, FormatVerticalAlignCenterIcon, ControlPlatformIcon, LayersIcon } from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/supplier",
    name: "supplier",
    component: Layout,
    meta: { title: "供应链", icon: FormatVerticalAlignCenterIcon },
    children: [
      {
        path: "/supplier/shelf",
        name: "supplierShelf",
        meta: {
          auth: [],
          keepAlive: true,
          title: "供应商货架"
        },
        component: () => import("@/pages/supplier/supplierShelf/index.vue")
      },
      {
        path: "/supplier/management",
        name: "supplierManagement",
        meta: {
          auth: [],
          keepAlive: true,
          title: "供应商管理"
        },
        component: () => import("@/pages/supplier/supplierManagement/index.vue")
      },
      // {
      //   path: "/supplier/auditing",
      //   name: "supplierAuditing",
      //   meta: {
      //     auth: [],
      //     keepAlive: true,
      //     title: "供应商审核"
      //   },
      //   component: () => import("@/pages/supplier/supplierAuditing/index.vue")
      // }
    ]
  }
];
