<template>
  <side-nav
    v-if="showSidebar"
    :showLogo="showSidebarLogo"
    :layout="setting.layout"
    :isFixed="setting.isSidebarFixed"
    :menu="sideMenu"
    :theme="mode"
    :isCompact="setting.isSidebarCompact"
    :maxLevel="setting.splitMenu ? 2 : 3"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import SideNav from './SideNav.vue';

import { SettingType } from '@/interface';
import path from "path";

export default Vue.extend({
  name: 'LayoutSidebar',
  components: {
    SideNav,
  },
  computed: {
    ...mapGetters({
      showSidebar: 'setting/showSidebar',
      showSidebarLogo: 'setting/showSidebarLogo',
      mode: 'setting/mode',
      menuRouters: 'permission/routers',
    }),
    setting(): SettingType {
      return this.$store.state.setting;
    },
    sideMenu() {
      const { layout, splitMenu } = this.$store.state.setting;
      let { menuRouters } = this
      if (localStorage.getItem("router") || localStorage.getItem("router")==='') {
        let routerArr = JSON.parse(localStorage.getItem("router"))
        let newRouterArr = [];
        routerArr.forEach((el) => {
          menuRouters.forEach((vl) => {
            if (el.path === vl.path) {
              let child = [];
              el.children.forEach((cl) => {
                vl.children.forEach((tl) => {
                  if (cl.path === tl.path) {
                    child.push({
                      children:[],
                      component: tl.component,
                      meta: tl.meta,
                      name: cl.title,
                      path: cl.path
                    })
                  }
                });
              });
              newRouterArr.push({
                children: child,
                component: vl.component,
                meta: vl.meta,
                name: vl.name,
                path: vl.path
              });
            }
          });
        });
        let map = new Map();
        for (let item of newRouterArr) {
          map.set(item.path, item);
        }
        newRouterArr = [...map.values()];
        this.$store.commit('',newRouterArr)
        menuRouters = newRouterArr
      }
      // console.log(this.$router)

      if (layout === 'mix' && splitMenu) {
        menuRouters.forEach((menu) => {
          if (this.$route.path.indexOf(menu.path) === 0) {
            menuRouters = menu.children.map((subMenu) => ({ ...subMenu, path: `${menu.path}/${subMenu.path}` }));
          }
        });
      }

      return menuRouters;
    },
  },
});
</script>
<style lang="less" scoped></style>
