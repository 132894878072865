
<div :class="layoutCls">
  <t-head-menu :class="menuCls" :theme="theme" expandType="popup" :value="active">
    <template #logo>
      <span v-if="showLogo" class="header-logo-container" @click="handleNav('/dashboard/base')">
        <logo-full class="t-logo" />
      </span>
      <div v-else class="header-operate-left">
        <t-button theme="default" aria-label="search" shape="square" variant="text" @click="changeCollapsed">
          <view-list-icon  class="collapsed-icon" />
        </t-button>
      </div>
    </template>
    <menu-content v-show="layout !== 'side'" style="color: #FFFFFF" class="header-menu" :navData="menu" />
    <template #operations>
      <div class="operations-container">
        <t-dropdown :min-column-width="125" trigger="click">
          <template #dropdown>
            <t-dropdown-menu>
              <t-dropdown-item class="operations-dropdown-container-item" @click="bingFs">
                <App-icon />
                绑定飞书
              </t-dropdown-item>
              <t-dropdown-item class="operations-dropdown-container-item" @click="handleLogout">
                <poweroff-icon />
                退出登录
              </t-dropdown-item>
            </t-dropdown-menu>
          </template>
          <t-button aria-label="avatar" class="header-user-btn" theme="default" variant="text">
            <template #icon>
              <user-circle-icon class="header-user-avatar" />
            </template>
            <div style="display: flex;align-items: center">
              <div class="header-user-account">{{ nickname }}</div>
              <t-tag
                style="margin-left: 10px" v-if="roles.length>0" v-for="item in roles" :key="item" theme="success"
                size="small" variant="light"
              >{{ item }}
              </t-tag>
            </div>
            <template #suffix>
              <chevron-down-icon />
            </template>
          </t-button>
        </t-dropdown>
      </div>
    </template>
  </t-head-menu>
</div>
