import Layout from "@/layouts/index.vue";
import {
  ChartBarIcon,
  ChartPieIcon,
  CodeIcon,
  DesktopIcon,
  RootListIcon,
  UsergroupIcon
} from "tdesign-icons-vue";

export default [
  {
    path: "/data",
    component: Layout,
    redirect: "/data/baseData",
    name: "data",
    meta: {
      title: "数据中心",
      keepAlive: true,
      icon: CodeIcon
    },
    children: [
      {
        path: "/data/base",
        name: "baseData",
        component: () => import("@/pages/data/baseData.vue"),
        meta: {
          title: "综合报表",
          isRemovePadding: true,
          keepAlive: true
        }
      },
      {
        path: "/data/stats",
        name: "statsData",
        component: () => import("@/pages/data/statsData.vue"),
        meta: { title: "统计日报", keepAlive: true, isRemovePadding: true }
      },
      {
        path: "/data/product",
        name: "productData",
        component: () => import("@/pages/data/productData.vue"),
        meta: { title: "商品分析", keepAlive: true, isRemovePadding: true }
      },
      {
        path: "/data/daily",
        name: "dailyData",
        component: () => import("@/pages/data/dailyData.vue"),
        meta: { title: "日龄分析", keepAlive: true, isRemovePadding: true }
      },
      {
        path: "/data/push",
        name: "pushData",
        component: () => import("@/pages/data/pushData.vue"),
        meta: { title: "排品分析", keepAlive: true, isRemovePadding: true }
      },
      {
        path: "/data/aud",
        name: "audData",
        component: () => import("@/pages/data/audData.vue"),
        meta: { title: "人群分析", keepAlive: true, isRemovePadding: true }
      },
      {
        path: "/data/xiaoBai",
        name: "xiaoBaiData",
        component: () => import("@/pages/data/XiaoBaiStoreData.vue"),
        meta: { title: "小白严选", keepAlive: true, isRemovePadding: true }
      }
    ]
  }
];
