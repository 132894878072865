
<div>
  <template v-for="item in list">
    <template v-if="!item.children || !item.children.length || item.meta?.single">
      <t-menu-item
          v-if="getHref(item)"
          :key="`href${item.path}`"
          :href="getHref(item)?.[0]"
          :name="item.path"
          :value="item.meta?.single ? item.redirect : item.path"
      >
        <template #icon>
          <t-icon v-if="typeof item.icon === 'string' && item.icon" :name="item.icon"/>
          <renderFnIcon :item="item"/>
        </template>
        {{ item.title }}
      </t-menu-item>
      <t-menu-item
          v-else
          :key="`${item.path}`"
          :to="item.path"
          :name="item.path"
          :value="item.meta?.single ? item.redirect : item.path"
      >
        <template #icon>
          <t-icon v-if="typeof item.icon === 'string' && item.icon" :name="item.icon"/>
          <renderFnIcon :item="item"/>
        </template>
        <div @click="reload()">
          {{ item.title }}
        </div>
      </t-menu-item>
    </template>
    <t-submenu v-else :name="item.path" :value="item.path" :title="item.title" :key="item.path">
      <template #icon>
        <t-icon v-if="typeof item.icon === 'string' && item.icon" :name="item.icon"/>
        <renderFnIcon :item="item"/>
      </template>
      <menu-content v-if="item.children" :nav-data="item.children"/>
    </t-submenu>
  </template>
</div>
