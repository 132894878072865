import {
  CircleIcon
} from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/live",
    name: "live",
    component: Layout,
    meta: { title: "直播间", icon: CircleIcon },
    children: [
      {
        path: "/live/anchor/manage",
        name: "anchorManage",
        meta: {
          auth: [],
          title: "主播管理",
          keepAlive: true,
        },
        component: () => import("@/pages/live/anchorManage/index.vue")
      },
      {
        path: "/live/manage",
        name: "liveManage",
        meta: {
          auth: [],
          keepAlive: true,
          title: "直播管理"
        },
        component: () => import("@/pages/live/liveManage/index.vue")
      },
      {
        path: "/live/materia",
        name: "liveMateria",
        meta: {
          auth: [],
          keepAlive: true,
          title: "运营设置"
        },
        component: () => import("@/pages/live/materia/index.vue")
      },
    ]
  }
];
