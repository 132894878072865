
<common-header
  v-if="showHeader"
  :showLogo="showHeaderLogo"
  :theme="mode"
  :layout="setting.layout"
  :isFixed="setting.isHeaderFixed"
  :menu="headerMenu"
  :isCompact="setting.isSidebarCompact"
  :maxLevel="setting.splitMenu ? 1 : 3"
/>
