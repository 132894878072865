import {
  ChartBubbleIcon, FileWordIcon, VideoIcon, LaptopIcon, AppIcon, RelativityIcon, ChatIcon, ForwardIcon, RootListIcon
} from "tdesign-icons-vue";
import Layout from "@/layouts/index.vue";

export default [
  {
    path: "/product",
    name: "product",
    component: Layout,
    meta: { title: "商品库", icon: AppIcon },
    children: [
      {
        path: "/product/manage",
        name: "productManage",
        meta: {
          auth: [],
          keepAlive: true,
          title: "商品管理"
        },
        component: () => import("@/pages/product/productManage/index.vue")
      },
      {
        path: "/product/push",
        name: "productPush",
        meta: {
          auth: [],
          keepAlive: true,
          title: "排品运营"
        },
        component: () => import("@/pages/product/productPush/index.vue")
      },
      {
        path: "/product/statistics",
        name: "productStatistics",
        meta: {
          auth: [],
          keepAlive: true,
          title: "商品统计"
        },
        component: () => import("@/pages/product/statistics/index.vue")
      },
      {
        path: "/product/comment",
        name: "productComment",
        meta: {
          auth: [],
          keepAlive: true,
          title: "商品评论"
        },
        component: () => import("@/pages/product/productComment/index.vue")
      },
      {
        path: "/product/stock",
        name: "productStock",
        meta: {
          auth: [],
          keepAlive: true,
          title: "库存管理"
        },
        component: () => import("@/components/result/developing.vue")
      },
      {
        path: "/product/tem",
        name: "productTem",
        meta: {
          auth: [],
          keepAlive: true,
          title: "运费模版"
        },
        component: () => import("@/pages/product/freightTem/index.vue")
      },
    ]
  }
];
