
<t-popup
  expand-animation
  placement="bottom-right"
  trigger="click"
  :visible="isNoticeVisible"
  @visible-change="onPopupVisibleChange"
>
  <template #content>
    <div class="header-msg">
      <div class="header-msg-top">
        <p>通知</p>
        <t-button v-if="unreadMsg.length > 0" class="clear-btn" variant="text" theme="primary" @click="setRead('all')"
          >清空</t-button
        >
      </div>
      <t-list v-if="unreadMsg.length > 0" class="narrow-scrollbar" :split="true">
        <t-list-item v-for="(item, index) in unreadMsg" :key="index">
          <div>
            <p class="msg-content">{{ item.content }}</p>
            <p class="msg-type">{{ item.type }}</p>
          </div>
          <p class="msg-time">{{ item.date }}</p>
          <template #action>
            <t-button size="small" variant="outline" @click="setRead('radio', item)"> 设为已读 </t-button>
          </template>
        </t-list-item>
      </t-list>

      <div v-else class="empty-list">
        <img src="https://tdesign.gtimg.com/pro-template/personal/nothing.png" alt="空" />
        <p>暂无通知</p>
      </div>
      <div class="header-msg-bottom">
        <t-button
          v-if="unreadMsg.length > 0"
          class="header-msg-bottom-link"
          variant="text"
          theme="primary"
          @click="goDetail"
          >查看全部</t-button
        >
      </div>
    </div>
  </template>
  <t-badge :count="unreadMsg.length" :offset="[15, 6]">
    <t-button theme="default" shape="square" variant="text" @click="isNoticeVisible = true">
      <mail-icon />
    </t-button>
  </t-badge>
</t-popup>
