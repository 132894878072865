<template>
  <div class="page">
    <t-card :bordered="false" hover-shadow size="small">
      <t-pagination
        :total="total"
        :page-size.sync="limitSize"
        v-model="page"
        show-sizer
        @current-change="pageChange"
        @page-size-change="pageSizeChange"
        :page-size-options="pageSizeOptions"
      />
    </t-card>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      limitSize: this.limit,
      pageSize: this.page,
      pageSizeOptions: [
        { label: "每页 10 条", value: 10 },
        { label: "每页 20 条", value: 20 },
        { label: "每页 30 条", value: 30 },
        { label: "每页 40 条", value: 40 },
        { label: "每页 50 条", value: 50 },
        { label: "每页 60 条", value: 60 },
        { label: "每页 100 条", value: 100 },
      ],
    };
  },
  methods: {
    pageChange(page) {
      this.$emit("pageChange", page);
    },
    pageSizeChange(limit) {
      this.$emit("pageSizeChange", limit);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}
</style>
