
<side-nav
  v-if="showSidebar"
  :showLogo="showSidebarLogo"
  :layout="setting.layout"
  :isFixed="setting.isSidebarFixed"
  :menu="sideMenu"
  :theme="mode"
  :isCompact="setting.isSidebarCompact"
  :maxLevel="setting.splitMenu ? 2 : 3"
/>
